import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";

function Topbar() {
    const currentUserUid = localStorage.getItem("myId");
    const tabsData = [
        {
            label: "Profile",
            path: `/Portfolio/id=${currentUserUid}`,
        },
        // {
        //     label: 'Saved Artist',
        //     path: `/Portfolio/id=${currentUserUid}/saved-artist/`
        // },
        {
            label: "Order History",
            path: `/Portfolio/id=${currentUserUid}/order-history`,
        },
    ];
    // Decide where the nav item is active
    const location = useLocation();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
    const [tabUnderlineLeft, setTabUnderlineLeft] = useState(2);

    const tabsRef = useRef([]);

    useEffect(() => {
        tabsData.map((data, idx) => {
            if (data.path === location.pathname) {
                setActiveTabIndex(idx);
            }
        });
    }, [location]);

    useEffect(() => {
        function setTabPosition() {
            const currentTab = tabsRef.current[activeTabIndex];
            setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
            setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
        }

        setTabPosition();
        window.addEventListener("resize", setTabPosition);

        return () => window.removeEventListener("resize", setTabPosition);
    }, [activeTabIndex]);

    return (
        <div className="flex px-auto w-full  sm:bg-[#121212] bg-[#121212] dark:bg-black">
            <div className="relative nav-items mx-auto text-center h-[45px]">
                <div className="relative flex space-x-8">
                    {tabsData.map((tab, idx) => {
                        return (
                            <Link
                                to={tab.path}
                                key={idx}
                                ref={(el) => (tabsRef.current[idx] = el)}
                                className="pt-2 pb-3 font-primary text-white"
                                onClick={() => setActiveTabIndex(idx)}
                            >
                                {tab.label}
                            </Link>
                        );
                    })}
                </div>
                <span
                    className="absolute bottom-0 block h-1 transition-all duration-300 bg-white"
                    style={{left: tabUnderlineLeft, width: tabUnderlineWidth}}
                />
            </div>
        </div>
    );
}

export default Topbar;
