import {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { IoIosCall } from "react-icons/io";

import {
  addDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  doc,
  onSnapshot,
  getDoc,
  serverTimestamp,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import { db } from "~/firebase";
import { getDisplayName, getUserInfoByUid } from "~/utils/common";
import {
  sendNotificationToSalesRep,
  datetime_diff,
  sendNewCommentNotify,
} from "~/utils/common";

import appContext from "~/contexts/AppContext";

import NoCredit from "~/components/molecules/Payments/NoCredit";
import makecall from "~/assets/_makecall.mp4";
import EditJobPost from "~/pages/EditJobPost";
import EditPostButton from "~/components/molecules/ButtonGroup/EditPostButton";
import DeletePostButton from "~/components/molecules/ButtonGroup/DeletePostButton";
import SendButton from "~/components/molecules/ButtonGroup/SendButton";

const Post = () => {
  const currentUserUid = localStorage.getItem("myId");
  // This userRole could be get from context userData.role
  const userRole = JSON.parse(localStorage.getItem("userdata")).role;
  const { userData, userAvatar, setNewJobsCount } = useContext(appContext);

  const navigate = useNavigate();
  let clickRef = useRef([]);
  const [currentSelectedJob, setCurrentSelectedJob] = useState(undefined);
  const [jobsList, setJobsList] = useState([]);
  const [editPost, setEditPost] = useState(false);
  const jobListRef = useRef(null);
  const tabsRef = useRef([]);
  const [postUsers, setPostUsers] = useState({});
  const [commentList, setCommentList] = useState([]);
  const inputCommentsRefs = useRef([]);
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState(0);
  // In your component, initialize these state variables
  const [displayedJobs, setDisplayedJobs] = useState(5);
  const myRole = JSON.parse(localStorage.getItem("userdata")).role;

  const [creditCharge, setCreditCharge] = useState(false);
  const audioref = useRef(null);

  const [callState, setCallState] = useState(false);

  Array.prototype.removeByValue = function (val) {
    for (let i = 0; i < this.length; i++) {
      if (this[i] === val) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  // Create support room
  const createSupportRoom = async () => {
    sendNotificationToSalesRep(currentUserUid, userData.username);
    setCallState(true);

    const roomInfo = {
      roomName: currentUserUid + "_support",
      creator: currentUserUid,
      creatorName: userData.username,
      response: false,
      reciever: "",
      timestamp: serverTimestamp(),
    };

    const collectionRef = collection(db, "supportRoom");

    const q = query(collectionRef, where("creator", "==", currentUserUid));

    // Get the document reference
    getDocs(q)
      .then((querySnapshot) => {
        // Check if the document exists
        if (!querySnapshot.empty) {
          // Document exists, update it
          const docRef = doc(db, "supportRoom", querySnapshot.docs[0].id);
          return updateDoc(docRef, roomInfo);
        } else {
          // Document doesn't exist, add it
          return addDoc(collectionRef, roomInfo);
        }
      })
      .then(() => {
        console.log("SupportRoom document updated or added successfully!");
      })
      .catch((error) => {
        console.error("Error updating or adding supportRoom document: ", error);
      });
  };

  const cancelCall = async () => {
    setCallState(false);
    const q = query(
      collection(db, "supportRoom"),
      where("creator", "==", currentUserUid)
    );

    const getAndDeleteDoc = async (q) => {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        deleteDoc(doc.ref);
      });
    };

    getAndDeleteDoc(q);
  };

  //Delete job post
  const deleteJob = (job) => {
    if (window.confirm("Are you Sure?")) {
      const collectionRef = collection(db, "jobpost");
      //Define the query to find the document with the correct Job ID
      const q = query(
        collectionRef,
        where("post_userid", "==", job.post_userid)
      );
      getDocs(q)
        .then((querySnapshot) => {
          // Check if the document exists
          if (!querySnapshot.empty) {
            // Document exists, delete it
            const docRef = doc(db, "jobpost", job.jobId);
            return deleteDoc(docRef);
          }
        })
        .then(() => {
          console.log("User document deleted successfully!");
        })
        .catch((error) => {
          console.error("Error deleting user document: ", error);
        });
    }
  };

  useEffect(() => {
    if (!callState) {
      cancelCall();
    }
    const messagesRef = collection(db, "supportRoom");
    // Group messages by conversation
    const sentMessagesQuery = query(
      messagesRef,
      where("roomName", "==", currentUserUid + "_support")
    );

    const unsubscribe1 = onSnapshot(
      sentMessagesQuery,
      (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().response == true && doc.data().reciever == "") {
            console.log("recieved call");
            navigate(`/supportRoom/${currentUserUid}`);
          }
        });
      },
      (error) => {
        console.error("Error fetching sent messages:", error);
      }
    );

    return () => {
      unsubscribe1();
    };
  }, []);

  useEffect(() => {
    if (callState) {
      audioref.current.volume = 0.5;
      audioref.current.play();
    }
  }, [callState]);

  const loadMoreJobs = () => {
    getJobPosts(displayedJobs + 5);
    setDisplayedJobs(displayedJobs + 5);
  };
  /**
   *  Get posted jobs
   */
  const getJobPosts = (limit_num) => {
    let q;

    if (myRole == "customer") {
      q = query(
        collection(db, "jobpost"),
        where("posterRole", "==", "sales"),
        orderBy("timestamp", "desc"),
        limit(limit_num)
      );
    } else {
      q = query(
        collection(db, "jobpost"),
        orderBy("timestamp", "desc"),
        limit(limit_num)
      );
    }

    const unsubscribe1 = onSnapshot(q, async (querySnapshot) => {
      const jobSet = [];
      const newJobs = [];
      querySnapshot.forEach((mdoc) => {
        const jobs = mdoc.data();
        jobs.jobId = mdoc.id;
        // console.log('jobs', jobs.timestamp.Date())
        jobSet.push(jobs);
        newJobs.push(jobs);
      });
      // sort jobs by timestamp
      newJobs.sort((a, b) => b.timestamp - a.timestamp);
      // console.log(newJobs)
      setJobsList(newJobs);
      jobListRef.current = newJobs;
      console.log("newjobs", newJobs);
      setNewJobsCount(0);
    });

    return () => {
      unsubscribe1();
    };
  };

  // const IdleTimer = useIdleTimer({
  //   onIdle: () => console.log("Hello"),
  //   timeout: 10000,
  // });

  // Fetch users once on component mount
  const fetchPostUsers = async () => {
    const newPostUsers = {};
    for (const job of jobsList) {
      if (!newPostUsers[job.post_userid]) {
        const user = await getUserInfoByUid(job?.post_userid);
        newPostUsers[job.post_userid] = user;
      }
    }
    setPostUsers(newPostUsers);
  };

  /**
   *  Start Getting Comments by Post Id
   */
  const getCommentsByPost = () => {
    const q = query(collection(db, "comments"));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const commentSet = [];
      await Promise.all(
        querySnapshot.docs.map(async (mdoc) => {
          const comments = mdoc.data();
          const docRef = doc(db, "users", comments.comment_userid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const commentUserInfo = docSnap.data();
            if (commentUserInfo) {
              comments.image = commentUserInfo.image ?? "";
              comments.name = commentUserInfo.username;
            } else {
              comments.image = "";
              comments.name = "";
            }
            commentSet.push(comments);
          }
        })
      );
      try {
        commentSet.sort((a, b) => a.timestamp?.seconds - b.timestamp?.seconds);
      } catch (e) {}
      setCommentList(commentSet);
    });

    return unsubscribe;
  };

  useEffect(() => {
    fetchPostUsers();
  }, [jobsList]);

  useEffect(() => {
    // get commnets by posted job
    const unsubscribe2 = getCommentsByPost();
    // get poste jobs by 5
    const unsubscribe1 = getJobPosts(5);

    return () => {
      unsubscribe2();
      unsubscribe1();
    };
  }, []);

  // The method to get each uers's profile by user Id
  const viewProfile = (id) => {
    // navigate(`/portfolio/id=${id}`);
    navigate(`/settings/profile-setting`);
  };

  // show comments profile
  const showProfile = (comment_userid) => {
    navigate(`/portfolio/id=${comment_userid}`);
  };

  const hideCommentsByIndex = useCallback((index) => {
    clickRef.current = index;
    // Hide comments for all tabs except the active one
    const currentTab = tabsRef.current[clickRef.current];
    if (currentTab != undefined && currentTab.classList.contains("hidden")) {
      currentTab.classList.remove("hidden");
    } else if (
      currentTab != undefined &&
      !currentTab.classList.contains("hidden")
    ) {
      currentTab.classList.add("hidden");
    }
  });

  // Additional
  const sendQuote = async (post_id, job_id, uid, color, index) => {
    if (comment == "") return;
    // let uploads = await uploadSelectedFiles()
    const saveData = {
      post_userid: post_id,
      jobid: job_id,
      comment_userid: currentUserUid,
      comment: comment,
      rate: rate,
      notify_checked: false,
      timestamp: serverTimestamp(),
      color: color,
    };
    setComment("");
    inputCommentsRefs.current[index].value = "";
    const commentData = await writeCommentsToDb(saveData);

    const users = await getCommentsUserListByPost(currentUserUid, post_id);
    await writeCommentUnreadToDb(users, commentData.id);
    sendNewCommentNotify(users, uid, job_id, comment);
  };

  const writeCommentsToDb = async (data) => {
    const debouncedWrite = async () => {
      const newComment = await addDoc(collection(db, "comments"), data);
      return newComment;
    };
    return debouncedWrite();
  };

  const writeCommentUnreadToDb = async (users, commentId) => {
    await users.forEach((user) => {
      addDoc(collection(db, "commentUnread"), {
        userId: user,
        commentId: commentId,
        readCheck: false,
      });
    });
  };

  const getCommentsUserListByPost = async (comment_userid, post_userid) => {
    if (comment_userid == "") {
      return;
    }
    let users = [];
    const q = query(
      collection(db, "comments"),
      where("post_userid", "==", post_userid)
    );

    const docSnap = await getDocs(q);
    users = docSnap.docs.map((docData) => {
      return docData.data().comment_userid;
    });
    if (comment_userid !== post_userid) {
      users.push(post_userid);
    }
    return users
      .filter((value, index, array) => array.indexOf(value) === index)
      .removeByValue(comment_userid);
  };

  // utility function to debounce function calls
  const debounce = (fn, delay) => {
    let timeoutId;

    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  };

  return (
    <div className="flex flex-col w-full max-w-[958px] mx-auto">
      <audio ref={audioref} autoPlay muted>
        <source src={makecall} type="audio/mpeg" />
      </audio>
      {jobsList &&
        jobsList.map((job, index) => {
          return (
            <div
              key={`${job.jobId}-${index}`}
              className="px-[44px] py-[36px] h-auto bg-[#EFEFEF] rounded-2xl mb-5"
            >
              <div className="flex flex-col">
                <div className="flex flex-row justify-between w-full mb-[20px]">
                  <div className="flex flex-row items-center gap-2">
                    <div
                      className="hover:cursor-pointer"
                      onClick={() => {
                        showProfile(job.post_userid);
                      }}
                    >
                      {Object.keys(postUsers).length &&
                        (postUsers[job.post_userid] &&
                        postUsers[job.post_userid]?.image != null ? (
                          <img
                            src={postUsers[job.post_userid]?.image}
                            className="w-[53px] h-[53px] rounded-full object-cover"
                          />
                        ) : (
                          <>
                            <div
                              className="w-[53px] h-[53px] rounded-full text-center mr-2 flex items-center justify-center"
                              style={{
                                backgroundColor:
                                  postUsers[job.post_userid]?.color,
                              }}
                            >
                              <p className="inline-block my-auto font-primary text-white align-middle">
                                {getDisplayName(
                                  postUsers[job.post_userid]?.username
                                )}
                              </p>
                            </div>
                          </>
                        ))}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-black text-[18px] font-bold font-primary">
                        <div
                          className="hover:cursor-pointer"
                          onClick={() => {
                            showProfile(job.post_userid);
                          }}
                        >
                          {postUsers[job.post_userid]?.username}{" "}
                        </div>
                        <span className="text-[18px] font-primary font-medium">
                          <span
                            className="text-[#BE0000] cursor-pointer"
                            onClick={() => {
                              setCreditCharge(true);
                            }}
                          >
                            <span className="underline"> Credit balance</span>:{" "}
                            {postUsers[job.post_userid]?.credit}{" "}
                          </span>
                          <span> (public)</span>
                        </span>{" "}
                      </span>
                      <span className="text-base font-primary text-[#505050]">
                        {datetime_diff(
                          job.timestamp?.seconds * 1000,
                          new Date().getTime()
                        )}{" "}
                        ago
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row gap-2">
                    <EditPostButton
                      currentUserUid={currentUserUid}
                      job={job}
                      setCurrentSelectedJob={setCurrentSelectedJob}
                      setEditPost={setEditPost}
                    />
                    <DeletePostButton
                      userRole={userRole}
                      deleteJob={deleteJob}
                      job={job}
                    />
                  </div>
                </div>
                <div className="flex flex-col mb-8 font-primary">
                  <div className="justify-between">
                    <p className="text-[18px]">{job.description}</p>
                    <div className="flex items-center mb-[5px] gap-3">
                      <p className="text-[15px] font-bold">Needs help with:</p>
                      {job.expertCategories.map((category, idx) => {
                        return (
                          <button
                            key={idx}
                            className="px-[15px] h-[25px] rounded-lg bg-[#232323] text-white text-[15px]"
                          >
                            {category}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  {currentUserUid === job.post_userid && (
                    <button
                      className="bg-transparent flex justify-center items-center gap-2  text-[18px] font-bold rounded-full py-[12px]  border-2 border-black bg-white"
                      onClick={() => {
                        createSupportRoom();
                      }}
                    >
                      <IoIosCall /> Ring for help
                    </button>
                  )}
                  <div className="grid grid-cols-5">
                    <div className="flex flex-row gap-[22.5px]">
                      {job.loomLink && (
                        <iframe
                          title="loom"
                          src={`https://www.loom.com/embed/${
                            job.loomLink.split("/share/")[1]
                          }`}
                          style={{ width: "170px", height: "105px" }}
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen
                        ></iframe>
                      )}
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      {job.uploadFiles &&
                        job.uploadFiles.map((file, idx) => {
                          return (
                            <img
                              src={file}
                              key={`${job.post_userid}-${index}`}
                              className="w-[169px] h-[105px] object-cover"
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center font-primary mb-[1px] cursor-pointer">
                  <button
                    type="button"
                    className="relative inline-flex items-center p-3 text-sm font-medium text-center text-white rounded-lg"
                  >
                    <svg
                      className="w-8 h-8 text-black"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      {" "}
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                    </svg>
                    <span className="sr-only">Notifications</span>
                  </button>
                  <div
                    className="text-black"
                    onClick={() => hideCommentsByIndex(index)}
                  >
                    Hide Comments...
                  </div>
                </div>
                <div
                  className="flex flex-col font-primary"
                  ref={(el) => (tabsRef.current[index] = el)}
                >
                  {commentList &&
                    commentList
                      .filter((value) => value.jobid == job.jobId)
                      .map((data, index) => {
                        return (
                          <div
                            className="flex flex-row items-center gap-2 mb-8"
                            key={`${job.jobId}-${index}`}
                          >
                            <div
                              className="hover:cursor-pointer"
                              key={data.comment_userid}
                              onClick={() => showProfile(data.comment_userid)}
                            >
                              {data.image ? (
                                <img
                                  src={data.image}
                                  className="w-[53px] h-[53px] rounded-full"
                                  alt="profile-icon"
                                />
                              ) : (
                                <div
                                  className="w-[53px] h-[53px] rounded-full text-center mr-2 flex items-center justify-center"
                                  style={{
                                    backgroundColor: data.color
                                      ? data.color
                                      : "#000000",
                                  }}
                                >
                                  <p className="inline-block my-auto text-white align-middle">
                                    {getDisplayName(data.name)}
                                  </p>
                                </div>
                              )}
                            </div>
                            <div
                              className="flex flex-col"
                              key={`${data.comment_userid}-${index}`}
                            >
                              <span
                                className="text-black font-bold text-[18px] hover:cursor-pointer"
                                onClick={() => showProfile(data.comment_userid)}
                              >
                                {data.name}
                              </span>
                              <span className="text-black text-[15px]">
                                {data.comment}
                              </span>
                            </div>
                          </div>
                        );
                      })}

                  <div className="flex flex-row gap-2 mb-1">
                    {userAvatar ? (
                      <img
                        src={userAvatar}
                        className="mr-2 rounded-full w-[53px] h-[53px] object-cover"
                        alt="default avatar"
                      />
                    ) : (
                      <div
                        className="w-[53px] h-[53px] rounded-full flex items-center justify-center text-center mr-2"
                        style={{ backgroundColor: userData?.color }}
                      >
                        <p className="inline-block my-auto text-white align-middle">
                          {getDisplayName(userData?.username)}
                        </p>
                      </div>
                    )}
                    <textarea
                      type="text"
                      ref={(el) => (inputCommentsRefs.current[index] = el)}
                      className="rounded-2xl w-full h-[62.25px] py-[13px] pl-[20.25px]"
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Write a comment..."
                    />
                  </div>
                  <div className="flex justify-end pt-4">
                    <SendButton
                      sendQuote={sendQuote}
                      job={job}
                      userData={userData}
                      index={index}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      {/* Load more jobs */}
      <div className="flex justify-between w-full">
        <button
          className="rounded-[5px] px-2 py-2 mx-auto text-black bg-[white]"
          onClick={() => loadMoreJobs()}
        >
          Load More Jobs
        </button>
      </div>
      {/* edit post */}
      {editPost && currentSelectedJob && (
        <div className="fixed inset-0 z-10 flex items-center justify-center px-4 py-8 overflow-y-auto ">
          <EditJobPost
            job={currentSelectedJob}
            onClose={() => {
              setEditPost(false);
            }}
          />
        </div>
      )}
      {creditCharge && (
        <NoCredit
          closeModal={() => {
            setCreditCharge(false);
          }}
          senderId={currentUserUid}
        />
      )}
    </div>
  );
};

export default Post;
