import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "~/contexts/AuthContext";

const PrivateRoute = ({ requiredRole }) => {
  const userRole = localStorage.getItem("userRole");
  const { isLoggedIn } = useAuth();
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // If a role is required and doesn't match, redirect to home or an unauthorized page
  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/"/>;
  }

  return <Outlet />;
};

export default PrivateRoute;
