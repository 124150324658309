import React, { useState, useRef, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  addDoc,
  updateDoc,
  collection,
  where,
  serverTimestamp,
  doc,
  getDocs,
  query,
} from "firebase/firestore";

import appContext from "../contexts/AppContext";
import { db, storage } from "../firebase";
import { MultiplicationIcon } from "../assets/icons";
import { sendNewJobNotify } from "../utils/common";

const skillOptions = [
  "After Effects",
  "Nuke",
  "Mocha",
  "Cinema 4D",
  "Octane",
  "Redshift",
  "Blender",
  "Houdini",
  "Embergen",
  "PFTrack",
  "Maya",
  "Silhouette",
  "Unreal Engine",
];

const EditJobPost = ({ job, onClose }) => {
  const currentUserUid = localStorage.getItem("myId");
  const { isMobile, skillSet } = useContext(appContext);
  // console.log("user:", user.loader)
  const hiddenFileInput = useRef(null);
  // Flip icon
  const [uploadFilesName, setUploadFilesName] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [expertCategories, setExpertCategories] = useState([]);
  const [skillOption, setSkillOption] = useState("After Effects");
  const selectedSkills = useRef([]);
  // form data
  // const [taskName, setTaskName] = useState(job.taskName);
  const [description, setDescription] = useState(job.description);
  const [loomLink, setLoomLink] = useState(job.loomLink);
  const [optional, setOptional] = useState(job.optional);
  // progress
  const [percent, setPercent] = useState(0);
  const navigate = useNavigate();
  const [newJobId] = useState(job.jobId);

  // Add upload files when select files of local
  const addUploadFilesName = useCallback((filename) => {
    setUploadFilesName((u) => [...u, filename]);
  }, []);

  const addUploadFiles = useCallback((file) => {
    setUploadFiles((u) => [...u, file]);
  }, []);

  const addExperts = useCallback(
    (selectedValue) => {
      if (expertCategories.includes(selectedValue)) return;
      selectedSkills.current = expertCategories;
      setExpertCategories((u) => [...u, selectedValue]);
    },
    [expertCategories]
  );

  const selectExpert = (e) => {
    var selectedValue = e.target.value;
    setSkillOption(selectedValue);
    addExperts(selectedValue);
  };

  // the method that remove skill options
  const removeSkillOptions = (data) => {
    const newExpertCategories = expertCategories.filter(
      (expert) => expert !== data
    );
    setExpertCategories(newExpertCategories);
  };
  //
  const uploadSelectedFiles = () => {
    return Promise.all(
      uploadFiles.map((uploadFile) => {
        const storageRef = ref(storage, `/files/${uploadFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, uploadFile);
        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setPercent(percent);
            },
            reject,
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then(resolve)
                .catch(reject);
            }
          );
        });
      })
    );
  };
  const handleEditJob = async () => {
    let uploads = await uploadSelectedFiles();
    const saveData = {
      // taskName: taskName,
      description: description,
      loomLink: loomLink,
      uploadFiles: uploads,
      expertCategories: expertCategories,
      post_userid: currentUserUid,
      optional: optional | "",
      timestamp: serverTimestamp(),
    };
    localStorage.removeItem("jobPostData");
    //Define the collection reference
    const collectionRef = collection(db, "jobpost");
    //Define the query to find the document with the correct user ID
    const q = query(collectionRef, where("post_userid", "==", currentUserUid));
    getDocs(q)
      .then((querySnapshot) => {
        // Check if the document exists
        if (!querySnapshot.empty) {
          // Document exists, update it
          const docRef = doc(db, "jobpost", newJobId);
          return updateDoc(docRef, saveData);
        } else {
          // Document doesn't exist, add it
          sendNewJobNotify(newJobId, skillSet, expertCategories);
          return addDoc(collectionRef, saveData);
        }
      })
      .then(() => {
        console.log("User document updated or added successfully!");
      })
      .catch((error) => {
        console.error("Error updating or adding user document: ", error);
      });
    onClose();
    //debouncedWrite();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    addUploadFilesName(fileUploaded.name);
    addUploadFiles(fileUploaded);
    if (fileUploaded) {
      const reader = new FileReader();
      reader.readAsDataURL(fileUploaded);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      {isMobile ? (
        <div className="flex flex-col items-center px-0 py-0 sm:px-6">
          <span className="text-[24px] leading-[30px] text-black font-bold">
            Order Instant Help
          </span>
          <div className="flex flex-col max-w-[677px] h-auto px-[23px] py-[32px] bg-[#EFEFEF] rounded-0 mt-[14px]">
            <div className="flex flex-col mb-8">
              <span className="text-[20px] mb-2">Headline</span>
              {/* <input
                type="text"
                onChange={(e) => setTaskName(e.target.value)}
                className="h-[48px] p-4 rounded-0 border border-[#C5C5C5] mb-[31px]"
                value={taskName}
                placeholder="Name of your task..."
              /> */}
              <textarea
                type="text"
                onChange={(e) => setDescription(e.target.value)}
                className="h-[96px] p-4 rounded-0 border border-[#C5C5C5]"
                name=""
                placeholder="Describe you problem to help the Experts determine if they are a good fit to help you..."
              />
            </div>
            <div className="flex flex-col mb-8">
              <span className="text-[20px] mb-2">
                Use{" "}
                <a
                  href="https://loom.com"
                  className="text-[#0054D1]"
                  target="_blank"
                  rel="noreferrer"
                >
                  Loom.com
                </a>{" "}
                to easily screencapture your question
              </span>
              <input
                type="text"
                onChange={(e) => setLoomLink(e.target.value)}
                className="h-[36px] p-4 rounded-0 border border-[#C5C5C5]"
                value={loomLink}
                name=""
                placeholder="Past the link to your Loom recording here..."
              />
            </div>
            <div className="flex flex-col mb-3">
              <div className="text-[20px] text-black mb-2">Upload files</div>
              <div className="flex flex-row">
                {uploadFilesName.map((file, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-[#FFFFFF] w-[96px] p-3  h-[96px] border border-solid border-[#C5C5C5] rounded-[10px] text-center flex justify-center items-center mr-2"
                    >
                      <span className="text-black text-[12px] text-center">
                        {file}
                      </span>
                    </div>
                  );
                })}
                <div
                  className="bg-[#FFFFFF] w-[96px] h-[96px] border border-solid border-[#C5C5C5] rounded-[10px] text-center flex justify-center items-center"
                  onClick={handleClick}
                >
                  <span className="text-[#D9D9D9] text-[25px]">+</span>
                </div>
              </div>
              <input
                style={{ display: "none" }}
                accept="image/*"
                type="file"
                name="myImage"
                ref={hiddenFileInput}
                onChange={handleChange}
                defaultValue=""
              />
            </div>
            <div className="flex flex-col mb-3">
              <span className="text-[20px]">
                What kind of expert do you need?
              </span>
              <select
                className="w-full mt-2 p-2 rounded-[4px] border border-[#C5C5C5] text-[20px] mb-2"
                onChange={selectExpert}
                value={skillOption}
              >
                {skillOptions.map((skill, index) => {
                  return (
                    <option key={index} value={skill}>
                      {skill}
                    </option>
                  );
                })}
              </select>
              <div className="flex flex-row flex-wrap gap-2">
                {expertCategories &&
                  expertCategories.map((expert, index) => {
                    return (
                      <div
                        key={index}
                        className="rounded-[4px] px-[10px] bg-[#DEDEDE] text-[20px] h-auto mr-2 text-[#878787] mb-2"
                      >
                        <button
                          className="mr-2"
                          onClick={() => removeSkillOptions(expert)}
                        >
                          <MultiplicationIcon fill="#878787" />
                        </button>
                        {expert}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-col mb-[40px]">
                <span className="text-black text-[20px] mb-2 block">
                  Budget & Deadline
                </span>
                <input
                  type="text"
                  onChange={(e) => setOptional(e.target.value)}
                  className="h-[48px] p-4 rounded-[4px] border border-[#C5C5C5] block"
                  value={optional}
                  name=""
                  placeholder="(Optional)"
                />
              </div>
              <div className="flex items-center mx-auto">
                <button
                  className="bg-black w-full h-[52px] rounded-[4px] px-2 py-2 text-[20px] text-white border border-[#353637]"
                  onClick={handleEditJob}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center px-0 py-0 sm:px-6 ">
          <div className="flex flex-col max-w-[677px] h-auto px-[29px] py-[12px] bg-[#cce5c7] sm:rounded-[20px] rounded-0 mt-[8px] relative">
            <button className="absolute right-1" onClick={() => onClose()}>
              <IoMdCloseCircle />
            </button>

            <div className="flex flex-col mb-8">
              {/* <span className="text-[15px] mb-2">Name your task</span>
              <input
                type="text"
                onChange={(e) => setTaskName(e.target.value)}
                value={taskName}
                className="h-[28px] p-4 rounded-[10px] border border-[#C5C5C5] mb-3"
                placeholder="Name of your task..."
              /> */}
              <div className="flex flex-col mb-2">
                <span className="text-[15px]">Talent Needed</span>
                <select
                  className="w-[150px] mt-2 p-2 rounded-xl border border-[#C5C5C5] text-[14px] mb-2"
                  onChange={selectExpert}
                  value={skillOption}
                >
                  {skillOptions.map((skill, index) => {
                    return (
                      <option key={index} value={skill}>
                        {skill}
                      </option>
                    );
                  })}
                </select>
                <div className="flex flex-row flex-wrap gap-2">
                  {expertCategories &&
                    expertCategories.map((expert, index) => {
                      return (
                        <div
                          key={index}
                          className="rounded-[10px] px-[10px] bg-[#DEDEDE] text-[20px] h-auto mr-2 text-[#878787] mb-2"
                        >
                          <button
                            className="mr-2"
                            onClick={() => removeSkillOptions(expert)}
                          >
                            <MultiplicationIcon fill="#878787" />
                          </button>
                          {expert}
                        </div>
                      );
                    })}
                </div>
              </div>
              <span className="text-[15px] mb-3">Description</span>
              <textarea
                type="text"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                className="h-[56px] p-4 rounded-[10px] border border-[#C5C5C5]"
                name=""
                placeholder="what do you need help with?"
              />
            </div>

            <div className="flex flex-col mb-3">
              <span className="text-[15px] mb-2">
                Use{" "}
                <a
                  href="https://loom.com"
                  className="text-[#0054D1]"
                  target="_blank"
                  rel="noreferrer"
                >
                  Loom.com
                </a>{" "}
                to easily screencapture your question
              </span>
              <input
                type="text"
                onChange={(e) => setLoomLink(e.target.value)}
                className="h-[36px] p-4 rounded-[10px] border border-[#C5C5C5]"
                name=""
                value={loomLink}
                placeholder="Past the link to your Loom recording here..."
              />
            </div>
            <div className="flex flex-col mb-2">
              <div className="text-[15px] text-black mb-2">Upload files</div>
              <div className="flex flex-row">
                {uploadFilesName.map((file, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-[#FFFFFF] w-[96px] p-3 text-center h-[96px] border border-solid border-[#C5C5C5] rounded-[10px]  flex justify-center items-center mr-2"
                    >
                      <span className="text-black text-[12px] text-center">
                        {file}
                      </span>
                    </div>
                  );
                })}
                <div
                  className="bg-[#FFFFFF] w-[96px] h-[96px] border border-solid border-[#C5C5C5] rounded-[10px] text-center flex justify-center items-center"
                  onClick={handleClick}
                >
                  <span className="text-[#D9D9D9] text-[25px]">+</span>
                </div>
              </div>
              <input
                style={{ display: "none" }}
                accept="image/*"
                value={uploadFiles}
                type="file"
                name="myImage"
                ref={hiddenFileInput}
                onChange={handleChange}
                defaultValue=""
              />
            </div>
            <div className="flex flex-col mb-[22px]">
              <span className="text-black text-[15px] mb-2 block">
                Budget & Deadline
              </span>
              <input
                type="text"
                onChange={(e) => setOptional(e.target.value)}
                className="h-[48px] p-4 rounded-[10px] border border-[#C5C5C5] block"
                value={optional}
                name=""
                placeholder="(Optional)"
              />
            </div>
            <div className="flex items-center mx-auto leading-4">
              <button
                className="bg-black w-[350px] h-[32px] rounded-[10px] px-2 py-2 text-[15px] text-white border border-[#353637]"
                onClick={handleEditJob}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditJobPost;
